@import "../../global.scss";

.home{
    background-color: white;  //backgroung of the hole thing
    display: flex; // display in line 

    @include mobile{
        flex-direction: column;
        align-items: center;

    }

    .right{
        flex: 0.5; //half of the screan
        //background-color: yellow;
        position: relative;
        .wrapper{
            width: 50%;
            height: 50%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include mobile{
            width: 90%;
            height: 50%;
            
            }             
            h1{
                font-size: 40px;
                margin: 10px 0;

                @include mobile{
                    font-size: 35px;
                }
            }
            h2{
                font-size: 30px;
                @include mobile{
                 font-size: 25px;
                }
            }
            h3{
                font-size: 25px;

                span{
                    font-size: inherit;
                    color: red;
                }

                @include mobile{
                 font-size: 15px;
                }
                
            }
        }
        a{
            position: absolute;
            bottom: 10px;
            left: 40px;

            img{
                width: 100px;
                animation:arrowBlink 2s infinite;

            }
            @keyframes arrowBlink {
                100%{
                    opacity: 0;
                }
                
            }
        }

    }
    .left{
       flex: 0.5;
       overflow: hidden;
       .imageContainer{
           width: 350px;
           height: 350px;
           background-color: black;
           border-radius: 50%;
           //
           display: flex;
           align-items: flex-end;
           justify-content: center;
           float: right;

           @include mobile{
               align-items: flex-start;
           }


           img{
               height: 98%;
               margin-left: 3px;

               @include mobile{
                   height: 98%;
               }
            
           }
       }
       //background-color: red;

    }
}