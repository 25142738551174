.app{
    height:100%;

    .sections{
      width: 100%; // the whole width for the sections  
      height: calc(100vh - 70px); //minus the aspace of the topbar
      position: relative; // it will show the text and no cover the text
      top: 70px;  // it will show the text after the bar bcs that is the size of the bar 
      scroll-behavior: smooth;// scroll slower
      scroll-snap-type: y mandatory; // allow acroll in  y axix mandatory 
      scrollbar-width: none;// deletes the scroll left bar

      > *{ // for all components inside witch are in App.jsx in sections 
          width: 100vw; // take the whole width
          height: calc(100vh - 70px); // the height of the sections minus the bar
          scroll-snap-align: start; //keeps the page alined to the start for firefox only
          &::-webkit-scrollbar{
              display: none; // this is for the other 
          }
      }
    }
}
