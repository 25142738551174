@import "../../global.scss"; // 
 
.topbar{ /* */
    width: 100%;
    height: 70px;
    background-color:white;
    color: $mainColor; // from global.scss color of text in bar 
    position: fixed;
    top: 0;
    z-index: 3; // it will show the bar 3 for the menue
    transition: all 1s ease; //slow trasition of the top bar when click 
    

    

    .wrapper{ /* in general */
        padding: 10px 30px; /* the padding move the top icon/word (hole thing) from height*/
        display: flex;   /*  move the top to the rigth*/
        align-items: center;  /* it centers */
        justify-content: space-between; /* space-between; gives space in between the items logo middle and rigth  */

        .left{
            .logo{
                font-size: 35px;  /* side */
                font-weight: 700;  /* blodnes */
                text-decoration: none;   /*  remove the line of the link */
                color: inherit;  /*  it keeps the orianl color*/
                margin-right: 40px; /* don't need this it gives margin from the logo EFG if thera was something else*/
                @include mobile{
                    margin-right: 0;
                   }  
            }       
    
        }
        
        .middle{
            display: flex; /* display icons in a line */
            align-items: center; /* centers */
            /* you may not need this becuse itemContiner */

            .itemContainer{
                display: flex; /* display icons in a line */
                align-items: center; /* centers */
                margin-left: 10px; /*give space between icons  */
    
                .icon{
                    font-size: 35px; /*changes size of icon you need to give a class name icon tot the icon*/
                     margin-right: 5px; /*if we have letter in span we don"t  */
                     color: $mainColor;
                }
                span{ // for span but is not used 
                    font-size: 15px;
                    font-weight: 500; /*you need to check inidex.html for 500 or other if you want to change  */
                }
            }

        }

       
        .right{

            .hamburger{
            
                width: 38px;
                height: 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;
            
                span{
                    width: 100%;
                    height: 3px;
                    background-color: $mainColor;
                    transform-origin: left; //this makes possible the x commetn for >
                    transition: all 2s ease;
                }
            }    
        }
       
    }


    &.active{ //if topbar  active = true change background and text and the rest
        background-color: $mainColor;
        color: white;

        .hamburger{

            span{ // when active this change the bars of the hamburger
                &:first-child{
                    background-color: white;
                    transform: rotate(45deg);
                }

                &:nth-child(2){
                    opacity: 0;
                }

                &:last-child{
                    background-color: white;
                    transform: rotate(-45deg);
                }
            }
        }
        .itemContainer{
            .icon{
                background-color: white;
            }
            
        }


    }

}