@import "../../global.scss";
.aboutme{
    background-color: rgb(94, 12, 12);
    display: flex;
    align-items: center;
    justify-content: center;

    h1{

        @include mobile{
            font-size: 35px;
            position: absolute;
            height: 520px;
       }
    }
    
   
    .arrow{
        height: 50px;
        position: absolute;
        cursor: pointer;

        &.left{
            left: 25px;
            transform: rotate(180deg);
            @include mobile{
                left: 5px;
               }
        }

        &.right{
            right: 25px;
            @include mobile{
                right: 5px;
               }
        }
        @include mobile{
            opacity: 60%;
           }
    }

    .slider{
        height: 300px;
        display: flex;
        position: absolute;
        left: 0;
        transition: all 1s ease-out;
        @include mobile{
            height: 700px;
           }
           
       
                       
        
        .container{
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;
           
         
            .item{
                width: 700px;
                height: 100%;
                background-color: white;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                @include mobile{
                    height: 50%;
                   }

                .left{
                    flex: 4;
                    height: 100%;
                    background-color: yellow;

                    .leftContainer{
                        width: 90%;
                        height: 80%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin-left: 10px;
                        margin-top: 10px;

                        .imgContainer{
                            width: 70px;
                            height: 70px;
                            border-radius: 50%;
                            background-color: rgb(17, 9, 6);
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img{
                                width: 50px;   
                            }

                        }
                        h2{
                            font-size: 16px;
                            @include mobile{
                                font-size: 14px;
                               }
                        }
                        p{
                            margin-left: 10px;
                            margin-right: 20px;
                            margin-top: 0;
                            font-size: 12px;
                            @include mobile{
                                font-size: 10px;
                               }
                        }
                        span{
                            font-size: 12px;
                            font-weight: 500;
                            text-decoration: underline;
                            cursor: pointer;
                        }

                    }

                }

                .right{
                  flex: 8;
                  height: 100%;
                  background-color: rgb(85, 17, 17);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  overflow: hidden;

                  img{
                      width: 475px;
                      height: 475x;
                      transform: rotate(0deg);
                  }

                }
            } 


        }
       
    }   
}



