@import "../../global.scss"; //

.menu{
    width: 300px;// of menue
    height: 100vh;//of menue
    background-color: $mainColor; //of menu
    position: fixed; 
    top: 0;
    right: -300px; //so it wont be shown when refresign  right: 0;
    z-index: 2;// it shows 
    // this are ajustment for the items
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 1s ease;
    @include mobile{
        width: 430px;
        right: -430px;
        opacity: 90%;
        background-color: black;
       }

    &.active{
        right: 0; //so it shows the menu you need to add this in manue 
    }

    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        color: white;
        font-weight: 400;
        font-size: 40px;
        //width: 60%; use it if menu moves 
        
        li{
            margin-bottom: 10px;
            @include mobile{margin-bottom: 30px;} 
            a{
                font-size: inherit;
                color: inherit;
                text-decoration: none;

            }    
            &:hover{
                font-weight: 700;
            }    
        }

    }

}