@import "../../global.scss";

.projectsList {
    font-size: 12px;
    margin-right: 50px;
    padding: 15px;
    border-radius: 10px;// this make it circular smoth the border
    cursor: pointer; 

    &.active{
        background-color: $mainColor;
        color: white;
    }
 }