@import "../../global.scss";
.contact{
    background-color: lightcyan;
    display: flex;
    @include mobile{
        flex-direction: column;
       }
    
    .left{
        flex: 1;
        overflow: hidden;
        img{
            height: 70%;
            width: 90%;
            margin-top: 70px;
            margin-left: 40px;
            @include mobile{
                height: 75%;
                width: 85%;
                margin-top: 75px;
            }
        }
    }

    .right{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
   

        h2{
            font-size: 30px;
        }

        form{
            width: 70%;
            height: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        
            input{
                
                width: 250px;
                height: 30px;
                font-size: 14px;
                
                @include mobile{
                    width: 200px;
                    height: 18px;
                }
            
            }

            textarea{
                width: 250px;
                height: 60px;
                font-size: 12px;
                @include mobile{
                    width: 200px;
                    height: 100px;
                }
            }
        
            span{
                color: red;
                font-size: 12px;
                display: none;
            }

            input:invalid[focused="true"] ~ span{
                display: block;
            }


            button{
                width: 150px;
                height: 20px;
                color: white;
                background-color: red;
                border: none;
                border-radius: 10px;
                font-weight: 500;
                cursor: pointer;
             
             
                &:focus{ // may not need this 
                    outline: none;
                }
            }
       
            h3{
                color: green;
                font-size: 12px;
            }

            ul{
                display: column;
                font-size: 12px;
            }
        }

    }

}
