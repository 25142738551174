@import "../../global.scss";

.services{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1{
        font-size:40px;
    }

    .container{
        width: 100%;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mobile{
            flex-direction: column;
        }

        .card{
            width: 250px;
            height: 70%;
            border-radius: 10px;
            box-shadow: 0px 0px 15px -8px rgb(24, 24, 26);
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 20px;
            transition: all 1s ease;
             
            &.featured{
                 width: 300px;
                 height: 80%;
                 margin: 0 30px;
             }
            &:hover{
                transform: scale(1.1);
            }
            .top{
                display: flex;
                align-items: center;
                justify-content: center;

                img{
                    &.left,
                    &.right{
                        height: 30px;
                    }
                    &.middle{
                        height: 70px;
                        width: 70px;
                        border-radius: 50%;
                        //object-fit: cover;
                        object-fit: inherit;
                        margin: 0 30px;
                    }
                }
            }
            .center{
                padding: 10px;
                background-color: rgb(239, 238, 241);
                border-radius: 10px;
            }
            .bottom{
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                h3{
                    font-size: 15px;
                    margin-bottom: 5px;
                }
                h4{
                    color: gray;
                    font-size: 15px;
                }
                
                
            }
        }
    }
}