@import "../../global.scss";

.projects{
    background-color:  white;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1{
        font-size: 40px;
    }
    ul{
        margin: 10px;
        padding:0;
        list-style: none;
        display: flex;
        @include mobile{
            margin: 20px;
            padding-left: 40px;
        }
   
       
    }
    .container{
        width: 70%;
        height: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap; // if the items bellow  dont fint in the line it goes to the nest


        .item{ //mesurement for items 
            width: 220px;
            height: 150px;
            border-radius: 20px;
            border: 1px solid lightskyblue;
            margin: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white; // use it if it does not have link
            // position: relative;// h3 is absolute so this should be relative h3 shows the wordls 
            transition: .5s ease;
            cursor: pointer;
            
            

            h3{
                position: absolute;
                font-size: 15px; 
                a{
                    text-decoration: none;
                    color: white;
                }               
            }

            img{
                width: 100px;
                height: 100px;
                object-fit: cover;
                z-index: 1;
            }

            &:hover{
                background-color: black;
                img{
                    opacity: 0.2;
                    z-index: 0;
                }
            }


        }
    }
}